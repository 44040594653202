<template>
  <b-card>
    <b-table-simple style="min-height: 0 !important">
      <b-thead>
        <b-tr>
          <b-th width="5%" />
          <b-th width="25%">รายชื่อเกม</b-th>
          <b-th>คอมมิชชั่น (ค่าคอมฯ ไม่สามารถให้มากกว่าเปอร์เซ็นตัวเองได้)</b-th>
          <b-th width="25%">สถานะเกม</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-if="form.length === 0">
          <b-td
              colspan="3"
              class="text-muted"
          >ไม่มีเกม</b-td>
        </b-tr>
        <b-tr>
          <b-td>
            <b-form-group label-for="select-all">
              <b-form-checkbox
                  id="select-all"
                  v-model="isSelectAll"
                  switch
                  @change="onSelectAllChange"
              >
              </b-form-checkbox>
            </b-form-group>
          </b-td>
          <b-td colspan="2">
            <strong>เปิดทุกเกม</strong>
          </b-td>
        </b-tr>
        <template v-for="game in form">
          <b-tr :key="game.id">
            <b-td class="pb-0">
              <b-form-group :label-for="`isSelect-${game.id}`">
                <b-form-checkbox
                    :id="`isSelect-${game.id}`"
                    v-model="game.isSelect"
                    switch
                />
              </b-form-group>
            </b-td>
            <b-td class="pb-0">
              <b-form-group :label-for="`name-${game.id}`">
                <b-form-input
                    :id="`name-${game.id}`"
                    v-model="game.name"
                    readonly
                ></b-form-input>
              </b-form-group>
            </b-td>
            <b-td class="pb-0">
              <b-form-group :label-for="`gameRate-${game.id}`">
                <b-form-input
                    :id="`gameRate-${game.id}`"
                    v-model="game.commissionRate"
                    :placeholder="game.masterCommissionRate"
                    :readonly="!game.isSelect"
                ></b-form-input>
              </b-form-group>
            </b-td>
            <b-td class="pb-0">
              <b-form-select v-model="game.status" :options="gameStatus" :disabled="!game.isSelect"></b-form-select>
            </b-td>
          </b-tr>
          <b-tr :key="`key-form-${game.id}`">
            <b-td
                colspan="2"
                class="border-top-0 pt-0"
            ></b-td>
            <b-td class="border-top-0 pt-0" colspan="2">
              <KeyForm
                  v-if="game && game.isSelect && !isAgent"
                  v-model="game.providerKey"
                  :game-name="game.rawName"
              />
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  page: {
    title: 'ตั้งค่าเอเย่นต์ | ตั้งค่าเกม',
  },
  components: {
    KeyForm: () => import('@components/games/key-form'),
  },
  props: {
    gameData: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      form: {},
      isSelectAll: true,
      gameStatus: [
        { value: 0, text: 'เปิด' },
        { value: 1, text: 'ปรับปรุง' },
        { value: 2, text: 'ปิด' },
      ],
    }
  },
  computed: {
    ...mapGetters(['isAgent'])
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update', val)
        this.setIsSelectAll()
      },
      deep: true
    },
  },
  created() {
    this.form = this.gameData
    this.setIsSelectAll()
  },
  methods: {
    setIsSelectAll() {
      const notSelectedGame = this.form.find((game) => !game.isSelect)
      this.isSelectAll = !notSelectedGame;
    },
    onSelectAllChange() {
      this.form = this.form.map((game) => {
        game.isSelect = this.isSelectAll
        return game
      })
    }
  }
}
</script>

<style></style>
